



















































import {Component, Ref, Vue} from "vue-property-decorator";
import ResourceCategory from "@/models/ResourceCategory";
import Resource from "@/models/Resource";
import ResourceCategoryService from "@/services/ResourceCategoryService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ResourceService from "@/services/ResourceService";
import PatchResourceDialogPanel from "@/components/panel/PatchResourceDialogPanel.vue";
import PostResourceCategoryPanel from "@/components/panel/PostResourceCategoryPanel.vue";
import PatchResourceCategoryPanel from "@/components/panel/PatchResourceCategoryPanel.vue";
import PostResourcePanel from "@/components/panel/PostResourcePanel.vue";

@Component({components: {
        PatchResourceCategoryPanel,
        PostResourceCategoryPanel,
        PatchResourceDialogPanel,
        PostResourcePanel
}})
export default class SecretaryAdminTab extends Vue {
    loading: boolean = false
    categories: ResourceCategory[] = []
    dialog: boolean = false
    resourceDialog: Resource = new Resource()


    created() {
        this.refresh()
    }

    refresh() {
        ResourceCategoryService.getResourceCategories(this, this.categories)
    }

    deleteResource(id: string) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar este documento?",
            () => ResourceService.deleteResource(this, id)
        ))
    }

    switchPatchResourceDialog(resource: Resource) {
        this.resourceDialog = {...resource}
        this.dialog ? this.dialog = false : this.dialog = true
    }
}




































import {Component, Vue} from "vue-property-decorator";
import Project from "@/models/Project";
import ProjectService from "@/services/ProjectService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import PostProjectPanel from "@/components/panel/PostProjectPanel.vue";
import PatchProjectDialogPanel from "@/components/panel/PatchProjectDialogPanel.vue";
@Component({
    components: {PatchProjectDialogPanel, PostProjectPanel}
})
export default class ProjectAdminTab extends Vue {
    loading: boolean = false
    dialog: boolean = false
    projects: Project[] = []
    project: Project = new Project()
    headers = [
        { text: 'Title', value: 'title' },
        { text: 'Banner', value: 'banner' },
        { text: "Orden", value: "location"},
        { text: 'Acciones', value: 'actions' }
    ]


    refresh() {
        ProjectService.getProjects(this, this.projects)
    }

    created() {
        this.refresh()
    }

    deleteProject(id: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar el proyecto?",
            () => ProjectService.deleteProject(this, id)
        ))
    }

    switchDialog(project: Project) {
        this.project = {...project}
        this.dialog ? this.dialog = false : this.dialog = true
    }
}

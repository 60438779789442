





































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ImageService from "@/services/ImageService";

@Component
export default class PostImagePanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    loading: boolean = false
    title: string = ""
    key: string = ""
    imageFile: File | null = null
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]
    imageRules = [(v: File) => v ? true : "Seleccione una Imagen"]


    postImage() {
        if (this.form.validate()) {
            ImageService.postImage(this, this.title, this.key, this.imageFile)
        }
    }
}

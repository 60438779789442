
























import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ResourceCategoryService from "@/services/ResourceCategoryService";

@Component
export default class PostResourceCategoryPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    loading: boolean = false
    title: string = ""
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]


    postCategory() {
        if (this.form.validate()) {
            ResourceCategoryService.postResourceCategory(this, this.title)
        }
    }
}

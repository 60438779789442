
































































import {Component, Prop, Ref, Watch, Vue} from "vue-property-decorator";
import Fullscreen from "vue-fullscreen/src/component";
import Marked from 'marked'
import ProjectService from "@/services/ProjectService";

@Component({components:{Fullscreen}})
export default class PostProjectPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    @Ref() readonly markedId!: HTMLParagraphElement
    marking: boolean = true

    loading: boolean = false
    title: string = ""
    body: string = ""
    bannerFile: File | null = null
    location: number | null = null
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]
    bodyRules = [(v: string) => v && v.length > 0 ? true : "Cuerpo requerido"]
    bannerRules = [(v: File) => v ? true : "Seleccione una Banner"]
    fullscreen: boolean = true

    mounted() {
        this.markedId.innerHTML = Marked(this.body)
    }

    checkmarkdown() {
        this.markedId.innerHTML = Marked(this.body!)
    }

    @Watch('body')
    onBody() {
        if (this.marking) this.markedId.innerHTML = Marked(this.body)
    }

    cleartext() {
        this.body = ""
        this.checkmarkdown()
    }

    fullscreenChange(fullscreen: boolean) {
        this.fullscreen = fullscreen
    }

    postProject() {
        if (this.form.validate()) {
            ProjectService.postProject(this, this.title, this.body, this.bannerFile, this.location)
        }
    }
}

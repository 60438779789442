

































































import {Component, Vue} from "vue-property-decorator";
import LoginService from "@/services/LoginService";
import SecretaryAdminTab from "@/components/tabs/SecretaryAdminTab.vue";
import DepartmentAdminTab from "@/components/tabs/DepartmentAdminTab.vue";
import InformationSheetsAdminTab from "@/components/tabs/InformationSheetsAdminTab.vue";
import BlogAdminTab from "@/components/tabs/BlogAdminTab.vue";
import SliderAdminTab from "@/components/tabs/SliderAdminTab.vue";
import TeamAdminTab from "@/components/tabs/TeamAdminTab.vue";
import ImageAdminTab from "@/components/tabs/ImageAdminTab.vue";
import ProjectAdminTab from "@/components/tabs/ProjectAdminTab.vue";
import FamilyAdminTab from "@/components/tabs/FamilyAdminTab.vue";
import InstitutionalDocumentTab from "@/components/tabs/InstitutionalDocumentTab.vue";

@Component({components: {
        InstitutionalDocumentTab,
        FamilyAdminTab,
        ProjectAdminTab,
        ImageAdminTab,
        SecretaryAdminTab,
        DepartmentAdminTab,
        InformationSheetsAdminTab,
        BlogAdminTab,
        SliderAdminTab,
        TeamAdminTab
}})
export default class AdminView extends Vue {
    items = [
        "Secretaría Virtual", "Departamentos", "Hojas Informativas",
        "Noticias", "Sliders", "Equipo Directivo", "Imagenes",
        "Proyectos", "Familias", "Documentos Institucionales"
    ]
    tab = null


    logout() {
        LoginService.logout(this)
    }
}






















































































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator"
import Blog from "@/models/Blog";
import BlogService from "@/services/BlogService";
import BlogLabel from "@/models/BlogLabel";
import {getModule} from "vuex-module-decorators";
import SnackbarModule from "@/store/SnackbarModule";
import Marked from "marked"

@Component
export default class PatchBlogDialogPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly blog!: Blog
    @Prop() readonly labels!: BlogLabel[]
    @Prop() readonly refresh!: any
    @Prop() readonly switchDialog!: any
    @Prop() dialog!: boolean
    @Ref() readonly markedId!: HTMLParagraphElement
    marking: boolean = true
    loading: boolean = false
    imageFile: File | null = null
    titleRules = [(v: string) => v && v.length > 0 ? true : "Nombre requerido"]
    bodyRules = [(v: string) => v && v.length > 0 ? true : "Cuerpo requerido"]

    checkmarkdown() {
        this.markedId.removeAttribute("hidden")
        this.markedId.innerHTML = Marked(this.blog.body!)
    }

    @Watch('blog.body')
    onBody() {
        if (this.marking) this.markedId.innerHTML = Marked(this.blog.body!)
    }

    cleartext() {
        this.blog.body = ""
        this.checkmarkdown()
    }

    patchBlog() {
        if (this.form.validate()) {
            BlogService.patchBlog(this, this.blog.title, this.blog.body, this.blog.label!.id!, this.blog.id!)
        }
    }

    deleteBlogImage(id: number, imageId: number) {
        BlogService.deleteBlogImage(this, id, imageId)
    }

    postBlogImage() {
        if (this.blog.images!.length < 10) {
            BlogService.postBlogImage(this, this.imageFile, this.blog.id!)
        } else {
            getModule(SnackbarModule).makeToast("No se pueden adjuntar mas de 10 imagenes")
        }
    }

    close() {
        this.refresh()
        this.switchDialog()
    }
}

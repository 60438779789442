

























































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator"
import Marked from "marked"
import Project from "@/models/Project";
import ProjectService from "@/services/ProjectService";

@Component
export default class PatchProjectDialogPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly project!: Project
    @Prop() readonly refresh!: any
    @Prop() readonly switchDialog!: any
    @Prop() dialog!: boolean
    @Ref() readonly markedId!: HTMLParagraphElement
    marking: boolean = true
    loading: boolean = false
    documentFile: File | null = null
    bannerFile: File | null = null
    location: number | null = null
    documentTitle: string = ""
    // titleRules = [(v: string) => v && v.length > 0 ? true : "Nombre requerido"]
    // bodyRules = [(v: string) => v && v.length > 0 ? true : "Cuerpo requerido"]
    // bannerRules = [(v: File) => v ? true : "Seleccione una Banner"]

    checkmarkdown() {
        this.markedId.removeAttribute("hidden")
        this.markedId.innerHTML = Marked(this.project.body!)
    }

    @Watch('project.body')
    onBody() {
        if (this.marking) this.markedId.innerHTML = Marked(this.project.body!)
    }

    cleartext() {
        this.project.body = ""
        this.checkmarkdown()
    }

    patchBlog() {
        if (this.form.validate()) {
            let location = (this.project.location == undefined ? null : this.project.location)
            ProjectService.patchProject(this, this.project, this.bannerFile, this.project.id!!, location)
        }
    }

    deleteProjectDocument(id: number, documentId: number) {
        ProjectService.deleteProjectDocument(this, id, documentId)
    }

    postProjectDocument() {
        ProjectService.postProjectDocument(this, this.documentTitle, this.documentFile, this.project.id!!)
    }

    close() {
        this.refresh()
        this.switchDialog()
    }
}






























import {Component, Vue} from "vue-property-decorator";
import Image from "@/models/Image";
import ImageService from "@/services/ImageService";
import PostImagePanel from "@/components/panel/PostImagePanel.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProjectService from "@/services/ProjectService";
@Component({
    components: {PostImagePanel}
})
export default class ImageAdminTab extends Vue {
    loading: boolean = false
    images: Image[] = []
    headers = [
        { text: 'Title', value: 'title' },
        { text: 'Key', value: 'key' },
        { text: 'Acciones', value: 'actions' }
    ]

    refresh() {
        ImageService.getImages(this, this.images)
    }

    created() {
        this.refresh()
    }

    deleteImage(id: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar esta imagen?",
            () => ImageService.deleteImage(this, id)
        ))
    }
}

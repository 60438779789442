








































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ResourceCategory from "@/models/ResourceCategory";
import ResourceService from "@/services/ResourceService";

@Component
export default class PostResourcePanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    @Prop() readonly categories!: ResourceCategory[]
    loading: boolean = false
    documentFile: File | null = null
    category: ResourceCategory = new ResourceCategory()
    title: string = ""
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]
    documentRules = [(v: File) => v ? true : "Seleccione un Documento"]


    postResource() {
        if (this.form.validate()) {
            ResourceService.postResource(this, this.documentFile, this.title, this.category.id!);
        }
    }
}

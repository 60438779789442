





































































import {Component, Vue, Watch} from "vue-property-decorator";
import Blog from "@/models/Blog";
import BlogService from "@/services/BlogService";
import BlogLabelService from "@/services/BlogLabelService";
import PostBlogPanel from "@/components/panel/PostBlogPanel.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import PostBlogLabelPanel from "@/components/panel/PostBlogLabelPanel.vue";
import PatchBlogDialogPanel from "@/components/panel/PatchBlogDialogPanel.vue";
import Options from "@/models/vue/Options";

@Component({components: {
        PostBlogPanel,
        PostBlogLabelPanel,
        PatchBlogDialogPanel
}})
export default class BlogAdminTab extends Vue {
    loading: boolean = false
    blogs: Blog[] = []
    labels: Blog[] = []
    dialog: boolean = false
    blog: Blog = new Blog()
    options: Options = new Options()
    page: number = 1
    pageCount: number = 0
    itemsPerPage: number = 10
    totalItems: number = 0

    headers = [
        { text: "Titulo", value: "title" },
        { text: "Etiqueta", value: "label" },
        { text: "Fecha de creacion", value: "createdAt" },
        { text: "Acciones", value: "actions" }
    ]

    headersLabels = [
        { text: "Titulo", value: "title" },
        { text: "Banner", value: "image" },
        { text: "Acciones", value: "actions" }
    ]


    @Watch("options")
    watchOptions() {
        BlogService.getBlogs(this, this.blogs, this.page - 1, this.itemsPerPage, null)
    }

    created() {
        this.refresh()
    }

    refresh() {
        BlogService.getBlogs(this, this.blogs, this.page - 1, this.itemsPerPage, null)
        BlogLabelService.getBlogLabels(this, this.labels)
    }

    deleteBlog(id: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar esta etiqueta?",
            () => BlogService.deleteBlog(this, id)
        ))
    }

    deleteLabel(id: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar esta etiqueta?",
            () => BlogLabelService.deleteBlogLabel(this, id)
        ))
    }

    switchDialog(blog: Blog) {
        this.blog = {...blog}
        console.log(this.blog)
        this.dialog ? this.dialog = false : this.dialog = true
    }

}

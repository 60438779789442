
























import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import InstitutionalDocumentCategoryService from "@/services/InstitutionalDocumentCategoryService";

@Component
export default class PostInstitutionalDocumentCategoryPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    loading: boolean = false
    title: string = ""
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]


    postCategory() {
        if (this.form.validate()) {
            InstitutionalDocumentCategoryService.postInstitutionalDocumentCategory(this, this.title)
        }
    }
}

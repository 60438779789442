






































import {Component, Vue} from "vue-property-decorator";
import Slider from "@/models/Slider";
import SliderService from "@/services/SliderService";
import PostSliderPanel from "@/components/panel/PostSliderPanel.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";

@Component({components: {PostSliderPanel}})
export default class SliderAdminTab extends Vue {
    loading: boolean = false
    sliders: Slider[] = []
    headers = [
        { text: "Title", value: "title"},
        { text: "Image", value: "image"},
        { text: "Orden", value: "location"},
        { text: "Enlace", value: "url"},
        { text: "Acciones", value: "actions"}
    ]


    created() {
        this.refresh()
    }

    refresh() {
        SliderService.getSliders(this, this.sliders)
    }

    deleteSlider(id: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar este slider?",
            () => SliderService.deleteSlider(this, id)
        ))
    }

    redirect(url: string) {
        window.location.href = url;
    }
}



































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator"
import BlogService from "@/services/BlogService";
import Marked from "marked"
import Project from "@/models/Project";
import ProjectService from "@/services/ProjectService";
import Family from "@/models/Family";
import FamilyService from "@/services/FamilyService";

@Component
export default class PatchFamilyDialogPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly family!: Family
    @Prop() readonly refresh!: any
    @Prop() readonly switchDialog!: any
    @Prop() dialog!: boolean
    @Ref() readonly markedId!: HTMLParagraphElement
    loading: boolean = false
    internal: boolean = false
    bannerFile: File | null = null
    imageFile: File | null = null
    titleRules = [(v: string) => v && v.length > 0 ? true : "Nombre requerido"]

    inicio() {
        this.markedId.removeAttribute("hidden")
        this.markedId.innerHTML = Marked(this.family.body!)
    }

    @Watch('family.body')
    onBody() {
        this.markedId.innerHTML = Marked(this.family.body!)
    }

    patchFamily() {
        if (this.form.validate()) {
            FamilyService.patchFamily(this, this.family, this.bannerFile, this.family.id!)
        }
    }

    deleteFamilyImage(id: number, imageId: number) {
        FamilyService.deleteFamilyImage(this, id, imageId)
    }

    postFamilyImage() {
        FamilyService.postFamilyImage(this, this.imageFile, this.family.id!)
    }

    close() {
        this.refresh()
        this.switchDialog()
    }
}

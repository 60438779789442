






































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import InstitutionalDocumentCategory from "@/models/InstitutionalDocumentCategory";
import InstitutionalDocumentCategoryService from "@/services/InstitutionalDocumentCategoryService";

@Component
export default class PatchInstitutionalDocumentCategoryPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    @Prop() readonly categories!: InstitutionalDocumentCategory[]
    loading: boolean = false
    category: InstitutionalDocumentCategory = new InstitutionalDocumentCategory()
    title: string = ""
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]


    patchCategory() {
        if (this.form.validate() && this.category.id) {
            InstitutionalDocumentCategoryService.patchInstitutionalDocumentCategory(this, this.title, this.category.id);
        }
    }

    deleteCategory() {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar esta categoria?",
            () => {
                if (this.category.id) {
                    return InstitutionalDocumentCategoryService.deleteInstitutionalDocumentCategory(this, this.category.id)
                }
            }
        ))
    }
}

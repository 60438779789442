





































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import TeacherContent from "@/models/TeacherContent";
import TeachersService from "@/services/TeachersService";
import DepartmentContent from "@/models/DepartmentContent";
import StringTool from "@/services/tool/StringTool";

@Component
export default class PostTeacherPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    @Prop() readonly departments!: DepartmentContent[]
    loading: boolean = false
    teacher: TeacherContent = new TeacherContent()
    department: DepartmentContent = new DepartmentContent()
    titleRules = [(v: string) => v && v.length > 0 ? true : "Este campo es requerido"]
    emailRules = [
        // (v: string) => v.length > 0 ? true : "Email requerido",
        (v: string) => StringTool.validateEmail(v) ? true : "Email no es válido"
    ]


    postTeacher() {
        if (this.form.validate() && this.department.id) {
            TeachersService.postTeacher(this, this.teacher, this.department.id);
        }
    }

}

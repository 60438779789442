












































import {Component, Vue} from "vue-property-decorator";
import DepartmentContent from "@/models/DepartmentContent";
import DepartmentService from "@/services/DepartmentService";
import PostDepartmentPanel from "@/components/panel/PostDepartmentPanel.vue";
import PostTeacherPanel from "@/components/panel/PostTeacherPanel.vue";
import PatchDepartmentPanel from "@/components/panel/PatchDepartmentPanel.vue";
import TeachersService from "@/services/TeachersService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";

@Component({components: {PostDepartmentPanel, PostTeacherPanel, PatchDepartmentPanel}})
export default class DepartmentAdminTab extends Vue {
    loading: boolean = false
    departments: DepartmentContent[] = []


    created() {
        this.refresh()
    }

    refresh() {
        DepartmentService.getDepartments(this, this.departments)
    }

    deleteTeacher(teacherId: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar este profesor?",
            () => TeachersService.deleteTeacher(this, teacherId)
        ))
    }
}

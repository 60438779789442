














































import {Vue, Component, Prop, Ref} from "vue-property-decorator";
import InstitutionalDocumentCategory from "@/models/InstitutionalDocumentCategory";
import InstitutionalDocument from "@/models/InstitutionalDocument";
import InstitutionalDocumentService from "@/services/InstitutionalDocumentService";

@Component
export default class PatchInstitutionalDocumentDialogPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly categories!: InstitutionalDocumentCategory[]
    @Prop() readonly institutionalDocument!: InstitutionalDocument
    @Prop() readonly refresh!: any
    @Prop() readonly switchDialog!: any
    @Prop() dialog!: boolean
    loading: boolean = false
    documentFile: File | null = null
    category: InstitutionalDocumentCategory = new InstitutionalDocumentCategory()
    titleRules = [(v: string) => v && v.length > 0 ? true : "Nombre requerido"]


    patchInstitutionalDocument() {
        if (this.form.validate()) {
            InstitutionalDocumentService.patchInstitutionalDocument(this, this.institutionalDocument, this.documentFile, this.category.id)
        }
    }

    close() {
        this.refresh()
        this.switchDialog()
    }
}

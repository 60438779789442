



























import {Component, Vue} from "vue-property-decorator";
import ConfigService from "@/services/ConfigService";
import Config from "@/models/Config";
import PostConfigTeacherPanel from "@/components/panel/PostConfigTeacherPanel.vue";

@Component({components:{PostConfigTeacherPanel}})
export default class TeamAdminTab extends Vue {
    loading: boolean = false
    teachers: any = []
    headers = [
        { text: "Cargo", value: "position" },
        { text: "Nombre", value: "name" },
        { text: "Correo", value: "email" },
        { text: "Orden", value: "location" },
        { text: "Acciones", value: "actions" }
    ]


    created() {
        this.refresh()

    }

    async refresh() {
        let list: Config[] = []
        this.teachers = []
        await ConfigService.getConfigTeachers(this, list)
        list.forEach(v => {
            const values: any = v.value!.split(",")
            const orden: any = v.location
            this.teachers.push({
                id: v.id,
                position: values[0],
                name: values[1],
                email: values[2],
                location: orden
            })
        })
    }

    deleteConfigTeacher(id: string) {
        ConfigService.deleteConfig(this, id)
    }
}













































import {Component, Vue} from "vue-property-decorator";
import Project from "@/models/Project";
import ProjectService from "@/services/ProjectService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import PostProjectPanel from "@/components/panel/PostProjectPanel.vue";
import PatchProjectDialogPanel from "@/components/panel/PatchProjectDialogPanel.vue";
import Blog from "@/models/Blog";
import Family from "@/models/Family";
import FamilyService from "@/services/FamilyService";
import PostFamilyPanel from "@/components/panel/PostFamilyPanel.vue";
import PatchFamilyDialogPanel from "@/components/panel/PatchFamilyDialogPanel.vue";
@Component({
    components: {PatchFamilyDialogPanel, PostFamilyPanel}
})
export default class FamilyAdminTab extends Vue {
    loading: boolean = false
    dialog: boolean = false
    families: Family[] = []
    family: Family = new Family()
    headers = [
        { text: 'Title', value: 'title' },
        { text: 'Url', value: 'url' },
        { text: 'Banner', value: 'banner' },
        { text: 'Acciones', value: 'actions' }
    ]


    refresh() {
        FamilyService.getFamilies(this, this.families)
    }

    created() {
        this.refresh()
    }

    deleteFamily(id: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar la Familia?",
            () => FamilyService.deleteFamily(this, id)
        ))
    }

    switchDialog(family: Family) {
        this.family = {...family}
        this.dialog ? this.dialog = false : this.dialog = true
    }
}





















































import {Component, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import InstitutionalDocumentCategory from "@/models/InstitutionalDocumentCategory";
import InstitutionalDocumentCategoryService from "@/services/InstitutionalDocumentCategoryService";
import InstitutionalDocumentService from "@/services/InstitutionalDocumentService";
import InstitutionalDocument from "@/models/InstitutionalDocument";
import PostInstitutionalDocumentCategoryPanel from "@/components/panel/PostInstitutionalDocumentCategoryPanel.vue";
import PatchInstitutionalDocumentCategoryPanel from "@/components/panel/PatchInstitutionalDocumentCategoryPanel.vue";
import PostInstitutionalDocumentPanel from "@/components/panel/PostInstitutionalDocumentPanel.vue";
import PatchInstitutionalDocumentDialogPanel from "@/components/panel/PatchInstitutionalDocumentDialogPanel.vue";

@Component({components: {
        PatchInstitutionalDocumentDialogPanel,
        PostInstitutionalDocumentPanel,
        PatchInstitutionalDocumentCategoryPanel,
        PostInstitutionalDocumentCategoryPanel
    }})
export default class InstitutionalDocumentTab extends Vue {
    loading: boolean = false
    categories: InstitutionalDocumentCategory[] = []
    dialog: boolean = false
    institutionalDocumentDialog: InstitutionalDocument = new InstitutionalDocument()


    created() {
        this.refresh()
    }

    refresh() {
        InstitutionalDocumentCategoryService.getInstitutionalDocumentCategories(this, this.categories)
    }

    deleteInstitutionalDocument(id: string) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar este documento?",
            () => InstitutionalDocumentService.deleteInstitutionalDocument(this, id)
        ))
    }

    switchPatchDocumentDialog(institutionalDocument: InstitutionalDocument) {
        this.institutionalDocumentDialog = {...institutionalDocument}
        this.dialog ? this.dialog = false : this.dialog = true
    }
}
















































import {Vue, Component, Prop, Ref} from "vue-property-decorator";
import Resource from "../../models/Resource";
import ResourceCategory from "../../models/ResourceCategory";
import ResourceService from "@/services/ResourceService";

@Component
export default class PatchResourceDialogPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly categories!: ResourceCategory[]
    @Prop() readonly resource!: Resource
    @Prop() readonly refresh!: any
    @Prop() readonly switchDialog!: any
    @Prop() dialog!: boolean
    loading: boolean = false
    resourceFile: File | null = null
    resourceCategory: ResourceCategory = new ResourceCategory()
    titleRules = [(v: string) => v && v.length > 0 ? true : "Nombre requerido"]


    patchResource() {
        if (this.form.validate()) {
            ResourceService.patchResource(this, this.resource, this.resourceFile, this.resourceCategory.id)
        }
    }

    close() {
        this.refresh()
        this.switchDialog()
    }
}

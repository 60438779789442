var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('PostBlogLabelPanel',{attrs:{"refresh":_vm.refresh}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-toolbar-title',[_vm._v("Etiquetas")])],1),_c('v-progress-linear',{attrs:{"indeterminate":true,"active":_vm.loading,"color":"warning"}}),_c('v-data-table',{attrs:{"headers":_vm.headersLabels,"items":_vm.labels,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"text":"","icon":"","color":"success","href":item.image.url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.deleteLabel(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('PostBlogPanel',{attrs:{"refresh":_vm.refresh,"labels":_vm.labels}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-toolbar-title',[_vm._v("Noticias")])],1),_c('v-progress-linear',{attrs:{"indeterminate":true,"active":_vm.loading,"color":"warning"}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.blogs,"loading":_vm.loading,"show-select":false,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"options":_vm.options,"loading-text":"Cargando...","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.label.title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.deleteBlog(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"text":"","icon":"","color":"success"},on:{"click":function($event){return _vm.switchDialog(item)}}},[_c('v-icon',[_vm._v("mdi-table-edit")])],1)]}}])})],1),_c('v-divider'),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('PatchBlogDialogPanel',{attrs:{"refresh":_vm.refresh,"blog":_vm.blog,"switchDialog":_vm.switchDialog,"dialog":_vm.dialog,"labels":_vm.labels}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
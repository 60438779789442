













































import {Component, Prop, Ref, Watch, Vue} from "vue-property-decorator";
import Fullscreen from "vue-fullscreen/src/component";
import Marked from 'marked'
import ProjectService from "@/services/ProjectService";
import FamilyService from "@/services/FamilyService";

@Component({components:{Fullscreen}})
export default class PostFamilyPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    @Ref() readonly markedId!: HTMLParagraphElement


    loading: boolean = false
    internal: boolean = false
    title: string = ""
    url: string = ""
    body: string = ""
    bannerFile: File | null = null
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]
    fullscreen: boolean = true

    mounted() {
        this.markedId.innerHTML = Marked(this.body)
    }

    @Watch('body')
    onBody() {
        this.markedId.innerHTML = Marked(this.body)
    }

    fullscreenChange(fullscreen: boolean) {
        this.fullscreen = fullscreen
    }

    postFamily() {
        if (this.form.validate()) {
            FamilyService.postFamily(this, this.title, this.url, this.body, this.bannerFile)
        }
    }
}

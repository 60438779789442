





































import {Component, Vue} from "vue-property-decorator";
import DepartmentContent from "@/models/DepartmentContent";
import DepartmentService from "@/services/DepartmentService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ResourceService from "@/services/ResourceService";
import PostDepartmentDocumentPanel from "@/components/panel/PostDepartmentDocumentPanel.vue";

@Component({components: {PostDepartmentDocumentPanel}})
export default class InformationSheetsAdminTab extends Vue {
    loading: boolean = false
    departments: DepartmentContent[] = []


    created() {
        this.refresh()
    }

    refresh() {
        DepartmentService.getDepartments(this, this.departments)
    }

    deleteDocument(id: number, documentId: number) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar este documento?",
            () => DepartmentService.deleteDepartmentDocument(this, id, documentId)
        ))
    }
}

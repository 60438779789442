




























import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import StringTool from "@/services/tool/StringTool";
import ConfigService from "@/services/ConfigService";

@Component
export default class PostConfigTeacherPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    loading: boolean = false
    fullName: string = ""
    email: string = ""
    position: string = ""
    location!: number | undefined
    titleRules = [(v: string) => v && v.length > 0 ? true : "Este campo es requerido"]
    emailRules = [
        (v: string) => v.length > 0 ? true : "Email requerido",
        (v: string) => StringTool.validateEmail(v) ? true : "Email no es válido"
    ]

    postConfigTeacher() {
        ConfigService.postConfigTeacher(this, `${this.position},${this.fullName},${this.email}`, this.location)
    }
}











































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import DepartmentContent from "@/models/DepartmentContent";
import DepartmentService from "@/services/DepartmentService";

@Component
export default class PostDepartmentDocumentPanel extends Vue {
    @Ref() readonly form!: HTMLFormElement
    @Prop() readonly refresh!: any
    @Prop() readonly departments!: DepartmentContent[]
    loading: boolean = false
    documentFile: File | null = null
    department: DepartmentContent = new DepartmentContent()
    title: string = ""
    titleRules = [(v: string) => v && v.length > 0 ? true : "Titulo requerido"]
    documentRules = [(v: File) => v ? true : "Seleccione un Documento"]


    postResource() {
        if (this.form.validate()) {
            DepartmentService.postDepartmentDocument(this, this.title, this.documentFile, this.department.id!)
        }
    }
}
